function Reviews() {
  return (
    <div className="container-fluid px-4 py-5 text-center" id="reviews" style={{backgroundColor: "#eeee74"}}>
      <h2 className="display-6 fw-bold text-body-emphasis lh-1 mb-3">Reviews</h2>
      <p className="fs-4">What our clients say about us:</p>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div className="feature col">
          <p><em>"SwiftDrive provides an exceptional service! From start to finish, renting with them was a breeze. The car was clean, the staff was friendly, and the whole process was seamless. Highly recommend!"</em> ~ Promise Ejama</p>
        </div>
        <div className="feature col">
          <p><em>"Five stars for SwiftDrive! Impeccable service, well-maintained cars, and a hassle-free experience. Will definitely be using them again!"</em> ~ Cardoso Olusoji</p>
        </div>
        <div className="feature col">
          <p><em>"Outstanding experience with SwiftDrive! Easy booking, great car selection, and fantastic customer service. Can't ask for more in a car hire company. Highly satisfied!"</em> ~ Amber Nwoko</p>
        </div>
      </div>
    </div>
  );
}

export default Reviews;