import Navbard from "./home/Navbar";

function Header() {
  return (
    <div className="header" id="header" style={{}}>
      <div className="section-content" style={{maxWidth: "1200px", margin: "0 auto"}}>
        <Navbard />
      </div>
    </div>
  );
}

export default Header;