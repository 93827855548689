import Elantra from "../../Hyundai Elantra.png"
import Tucson from "../../Hyundai Tucson.png"
import GX460 from "../../Lexus GX 460.png"
import Sentra from "../../Nissan Sentra.png"
import Corolla from "../../Toyota Corolla.png"
import Prado2018 from "../../Toyota Prado 2018.png"
import Hilux from "../../Hilux.png"
import Hiace from "../../Hiace.png"
import Rav4 from "../../Rav4.png"


function Cars() {
  const fleet = [
    {
      "id": 1,
      "name": "Toyota Prado",
      "properties": "Capacity: 7 Persons <br/>Doors: 4 <br/>Air Condition: Yes <br/>Transmission: Automatic",
      "image": Prado2018
    },
    {
      "id": 2,
      "name": "Toyota Hilux",
      "properties": "Capacity: 5 Persons <br/>Doors: 4 <br/>Air Condition: Yes<br/>Transmission: Automatic",
      "image": Hilux
    },
    {
      "id": 3,
      "name": "Lexus GX 460",
      "properties": "Capacity: 5 Persons <br/>Doors: 4 <br/>Air Condition: Yes <br/>Transmission: Automatic",
      "image": GX460
    },
    {
      "id": 4,
      "name": "Toyota Rav 4",
      "properties": "Capacity: 5 Persons <br/>Doors: 4 <br/>Air Condition: Yes<br/>Transmission: Automatic",
      "image": Rav4
    },
    {
      "id": 5,
      "name": "Toyota Hiace",
      "properties": "Capacity: 10 Persons <br/>Doors: 4 <br/>Air Condition: Yes<br/>Transmission: Automatic",
      "image": Hiace
    },
    {
      "id": 6,
      "name": "Toyota Corolla",
      "properties": "Capacity: 4 Persons <br/>Doors: 4 <br/>Air Condition: Yes<br/>Transmission: Automatic",
      "image": Corolla
    },
    {
      "id": 7,
      "name": "Hyondai Elantra",
      "properties": "Capacity: 4 Persons <br/>Doors: 4 <br/>Air Condition: Yes<br/>Transmission: Automatic",
      "image": Elantra
    },
    {
      "id": 8,
      "name": "Nissan Sentra",
      "properties": "Capacity: 4 Persons <br/>Doors: 4 <br/>Air Condition: Yes <br/>Transmission: Automatic",
      "image": Sentra
    },
    {
      "id": 9,
      "name": "Hyundai Tucson",
      "properties": "Capacity: 4 Persons <br/>Doors: 4 <br/>Air Condition: Yes <br/>Transmission: Automatic",
      "image": Tucson
    }
  ]
  return (
    <div className="section container" id="cars" style={{marginTop: "5em", marginBottom: "5em"}}>
      <h3 className="display-6 fw-bold text-body-emphasis lh-1 mb-3">Featured Cars</h3>
      <p className="fs-4">A Fleet Of Our Most Requested Cars</p>
      <div id="carouselExampleDark" className="carousel carousel-dark slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 6"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="6" aria-label="Slide 7"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="7" aria-label="Slide 8"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="8" aria-label="Slide 9"></button>
        </div>
        <div className="carousel-inner text-left">
          {
            fleet.map((car) => {
              return (
                <div className={car.id == 1? "carousel-item active": "carousel-item"} data-bs-interval="2000" key={car.id}>
                  <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6" style={{maxWidth: "500px"}}>
                      <img src={car.image} className="d-block mx-lg-auto img-fluid" alt={car.name}  loading="lazy" />
                    </div>
                    <div className="col-lg-6">
                      <h3 className="display-7 fw-bold text-body-emphasis lh-1 mb-3">{car.name}</h3>
                      <p className="lead" dangerouslySetInnerHTML={{ __html: car.properties}}></p>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Cars;