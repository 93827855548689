import React, { useState, useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const hires = {
  "suvs": [
    {"car": "2021/22 TOYOTA PRADO (BEST SELLER)", "price": 110000},
    {"car": "2018 PRADO DIRECT", "price": 110000},
    {"car": "2009 LEXUS 470", "price": 50000},
    {"car": "2020 LEXUS 460 UPGRADED", "price": 110000},
    {"car": "2020 LEXUS LX570", "price": 350000},
    {"car": "LEXUS RX350 2020", "price": 95000},
    {"car": "2014 LX 570", "price": 150000},
    {"car": "2015 LX 570 (BULLET PROOF)", "price": 550000},
    {"car": "2020 LANDCRUISER", "price": 200000},
    {"car": "2020 LANDCRUISER", "price": 150000},
    {"car": "2019 GX 460 WHITE", "price": 200000},
    {"car": "2019 MERCEDES GLE43", "price": 300000},
    {"car": "2019 MERCEDES AMG", "price": 350000},
    {"car": "2018 RANGE ROVER SPORT", "price": 350000},
    {"car": "2015 LANDCRUISER (BULLET PROOF)", "price": 500000},
    {"car": "2021 LANDRUISER BULLETPROOF", "price": 550000},
    {"car": "2014 HILUX", "price": 70000},
    {"car": "2020 HILUX", "price": 80000},
    {"car": "2017/18 HILUX", "price": 90000},
    {"car": "2020 HIGHLANDER", "price": 120000},
    {"car": "2014 GX 460", "price": 160000},
  ],
  "sedans": [
    {"car": "2013 CAMRY", "price": 50000},
    {"car": "2015 CAMRY", "price": 55000},
    {"car": "2020 COROLLA", "price": 60000},
    {"car": "2020 TOYOTA CAMRY", "price": 120000},
  ],
  "buses": [
    {"car": "HIACE BUS", "price": 95000},
    {"car": "COASTER BUS", "price": 110000},
    {"car": "2019 HIACE BUS", "price": 140000},
  ],
  "interstate": [
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Sedan) 2013 Camry", "price": 60000},
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Sedan) 2015 Camry", "price": 65000},
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Sedan) 2020 Corolla", "price": 65000},
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Bus) Hiace", "price": 110000},
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Bus) Coaster", "price": 125000},
    {"car": "Benin to Ekpoma/Irrua/Uromi - (Bus) 2019 Hiace", "price": 150000},
    {"car": "Benin to Ekpoma/Uromi/Irrua - (SUV) PRADO", "price": 140000},
    {"car": "Benin to Ekpoma/Uromi/Irrua - (SUV) PRADO and Hilux", "price": 270000},

    {"car": "Abuja to Jos, Lafia - (Sedan) 2013 Camry", "price": 60000},
    {"car": "Abuja to Jos, Lafia - (Sedan) 2015 Camry", "price": 65000},
    {"car": "Abuja to Jos, Lafia - (Sedan) 2020 Corolla", "price": 65000},

    {"car": "Abuja to Jos, Lafia - (Bus) Hiace", "price": 110000},
    {"car": "Abuja to Jos, Lafia - (Bus) Coaster", "price": 125000},
    {"car": "Abuja to Jos, Lafia - (Bus) 2019 Hiace", "price": 150000},

    {"car": "Abuja to Jos - (SUV) PRADO", "price": 300000},
    {"car": "Abuja to Jos - (SUV) PRADO with Hilux", "price": 600000},
    {"car": "Abuja to Jos - (SUV) Hilux", "price": 250000},
    {"car": "Abuja to Jos - (SUV) Bulletproof LANDCRUISER", "price": 900000},

    {"car": "Benin to Abuja - (SUV) PRADO", "price": 600000},
    {"car": "Benin to Delta - (SUV) PRADO", "price": 180000},
  ],
  "pickup": [
    {"car": "Toyota PRADO", "price": 70000},
    {"car": "Toyota Landcruiser", "price": 70000},
    {"car": "LX 460", "price": 70000},
    {"car": "Toyota RAV4", "price": 55000},
    {"car": "Toyota Hilux", "price": 60000},
  ]
};

function ReservationForm() {
  const [carType, setCarType] = useState('suvs');
  const [car, setCar] = useState(hires.suvs[0].car); // Initialize car selection with the first car in the 'suvs' array
  const [price, setPrice] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [service, setService] = useState('Daily Rental');
  const [mopol, setMopol] = useState(0);
  const [location, setLocation] = useState('Abuja');
  const [pickupLocation, setPickupLocation] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [dropOffLocation, setDropOffLocation] = useState('');
  const [bookingplan, setBookingplan] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('1');

  const [step, setStep] = useState(1);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', {
      firstName,
      lastName,
      email,
      phoneNumber,
      service,
      mopol,
      location,
      carType,
      car,
      price,
      pickupLocation,
      pickupDate,
      dropOffLocation,
      bookingplan,
      numberOfDays
    });
    
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };
  
  const handlePrevStep = () => {
    setStep(step - 1);
  };

  
  
  return (
    <div className={`modal fade`} id="reservationModal" tabIndex="-1" role="dialog" aria-labelledby="reservationModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="reservationModalLabel">Reservation Form - Step {step}</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          <p>Please note that all fields are required.</p>
            {step === 1 && (
              <Step1 
                firstName={firstName} 
                setFirstName={setFirstName} 
                lastName={lastName} 
                setLastName={setLastName} 
                email={email} 
                setEmail={setEmail} 
                phoneNumber={phoneNumber} 
                setPhoneNumber={setPhoneNumber} 
                handleNextStep={handleNextStep} 
              />
            )}
            {step === 2 && (
              <Step2 
                service={service}
                setService={setService}
                location={location} 
                setLocation={setLocation} 
                carType={carType} 
                setCarType={setCarType} 
                car={car} 
                setCar={setCar} 
                setPrice={setPrice}
                mopol={mopol}
                setMopol={setMopol}
                handlePrevStep={handlePrevStep} 
                handleNextStep={handleNextStep} 
              />
            )}
            {step === 3 && (
              <Step3 
                pickupLocation={pickupLocation} 
                setPickupLocation={setPickupLocation} 
                pickupDate={pickupDate} 
                setPickupDate={setPickupDate} 
                dropOffLocation={dropOffLocation} 
                setDropOffLocation={setDropOffLocation} 
                numberOfDays={numberOfDays} 
                setNumberOfDays={setNumberOfDays} 
                handlePrevStep={handlePrevStep} 
                handleSubmit={handleSubmit}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNumber={phoneNumber}
                location={location}
                carType={carType}
                car={car}
                price={price}
                service={service}
                mopol={mopol}
                bookingplan={bookingplan}
                setBookingplan={setBookingplan}
                pickupTime={pickupTime}
                setPickupTime={setPickupTime}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
  }
  
  function Step1({ firstName, setFirstName, lastName, setLastName, email, setEmail, phoneNumber, setPhoneNumber, handleNextStep }) {
  return (
    <form onSubmit={handleNextStep} className="p-4 p-md-5 border rounded-3 bg-body-tertiary">
      {/* Step 1 fields */}
      {/* First Name */}
      <div className="form-group">
        <label htmlFor="firstName">First Name *</label>
        <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </div>
      {/* Last Name */}
      <div className="form-group">
        <label htmlFor="lastName">Last Name *</label>
        <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </div>
      {/* Email */}
      <div className="form-group">
        <label htmlFor="email">Email *</label>
        <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      {/* Phone Number */}
      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number *</label>
        <input type="tel" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </div>
      <button type="submit" className="btn btn-primary">Next</button>
    </form>
  );
  }
  
  function Step2({ service, setService, location, setLocation, carType, setCarType, car, setCar, handlePrevStep, handleNextStep, setPrice, mopol, setMopol }) {
    const handleCarTypeChange = (event) => {
      setCarType(event.target.value);
      setCar(hires[event.target.value][0].car); // Set car selection to the first car in the selected car type array
    };

    useEffect(() => {
      const carArray = hires[carType];
      const carObject = carArray.find(item => item.car === car);
      if (carObject) {
        const price = carObject.price;
        console.log("Price for", car, "in", carType, "category is", price);
        setPrice(price);
      }
    }, [car])

    useEffect(() => {
      if (service === "Interstate/Intrastate Travel") {
        setCarType("interstate");
        setLocation("N/A");
      }else if (service === "Daily Rental") {
        setCarType('suvs')
      } else if (service === "Airport Pickup") {
        setCarType('pickup')
      }
    }, [service])

    const Mopol = () => {
      return (
        <div className="form-group">
          <label htmlFor="mopol">Escort Service (Number of Mopol) *</label>
          <select className="form-control" id="mopol" value={mopol} onChange={(e) => setMopol(e.target.value)}>
            {[...Array(5)].map((_, index) => (
              <option key={index} value={index}>{index}</option>
            ))}
          </select>
        </div>
      )
    }

    const Location = () => {
      return(
        <div className="form-group">
          <label htmlFor="location">Location/Departure *</label>
          <select className="form-control" id="location" value={location} onChange={(e) => setLocation(e.target.value)}>
            <option value="Abuja">Abuja</option>
            <option value="Benin">Benin</option>
          </select>
        </div>
      )
    }

    const CarType = () => {
      return(
        <div className="form-group">
          <label htmlFor="carType">Car Type *</label>
          <select className="form-control" id="carType" value={carType} onChange={handleCarTypeChange}>
            <option value="suvs">SUV</option>
            <option value="sedans">Sedan</option>
            <option value="buses">Bus</option>
          </select>
        </div>
      )
    }

    const AllCars = () => {
      return(
        hires[carType].map((item, index) => (
          <option key={index} value={item.car}>{item.car} - N{item.price.toLocaleString('en-US')}</option>
        ))
      )
    }

    const InterstateIntraCars = () => {
      return(
        hires['interstate'].map((item, index) => (
          <option key={index} value={item.car}>{item.car} - N{item.price.toLocaleString('en-US')}</option>
        ))
      )
    }

    const AirportPickup = () => {
      return(
        hires['pickup'].map((item, index) => (
          <option key={index} value={item.car}>{item.car} - N{item.price.toLocaleString('en-US')}</option>
        ))
      )
    }
  return (
    <form onSubmit={handleNextStep} className="p-4 p-md-5 border rounded-3 bg-body-tertiary">
      {/* Step 2 fields */}
      {/* Location */}
      <div className="form-group">
        <label htmlFor="service">Service *</label>
        <select className="form-control" id="service" value={service} onChange={(e) => setService(e.target.value)}>
          <option value="Daily Rental">Daily Rental</option>
          <option value="Airport Pickup">Airport Pickup</option>
          <option value="Interstate/Intrastate Travel">Interstate/Intrastate Travel</option>
        </select>
      </div>
      {/* Location */}
      {
        (service === "Daily Rental" || service === "Airport Pickup") ? <Location /> : null
      }
      {/* Car Type */}
      {
        (service === "Daily Rental") ? <CarType /> : null
      }
      {/* Car */}
      <div className="form-group">
        <label htmlFor="car">Select {service === "Interstate/Intrastate Travel"? "Transit & " : null } Vehicle *</label>
        <select className="form-control" id="car" value={car} onChange={(e) => setCar(e.target.value)}>
          {service === "Daily Rental" ? <AllCars /> : null}
          {service === "Airport Pickup" ? <AirportPickup /> : null}
          {service === "Interstate/Intrastate Travel" ? <InterstateIntraCars /> : null}
        </select>
      </div>
      {/* Mopol */}
      <Mopol />
      <button type="button" className="btn btn-secondary mr-2" onClick={handlePrevStep}>Previous</button>
      <button type="submit" className="btn btn-primary">Next</button>
    </form>
  );
  }
  
  function Step3({ pickupLocation, setPickupLocation, pickupDate, setPickupDate, dropOffLocation, setDropOffLocation, numberOfDays, setNumberOfDays, handlePrevStep, handleSubmit, firstName, lastName, email, phoneNumber, location, carType, car, price, service, mopol, bookingplan, setBookingplan, pickupTime, setPickupTime }) {

    const handlePaystackSuccessAction = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      console.log(reference);
    };
  
    // you can call this function anything
    const handlePaystackCloseAction = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

    useEffect(() => {
      // Load Paystack script dynamically
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v1/inline.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        // Clean up: Remove the Paystack script when the component unmounts
        document.body.removeChild(script);
      };
    }, []);
  
    const handlePayment = () => {
      // Initialize payment with Paystack
      const handler = window.PaystackPop.setup({
        email: email,
        amount: price * 100 * numberOfDays + (mopol * 2000000), // Amount in kobo (e.g., ₦100)
        currency: 'NGN',
        ref: (new Date()).getTime().toString(), //use time stamp instead of random reference //`${Math.floor(Math.random() * 1000000000) + 1}`, // Generate unique reference
        metadata: {
          custom_fields: [
            {
              "display_name": "Customer Name",
              "variable_name": "Customer Name",
              "value": `${firstName} ${lastName}`
            },
            {
              "display_name": "First Name",
              "variable_name": "First Name",
              "value": firstName
            },
            {
              "display_name": "Last Name",
              "variable_name": "Last Name",
              "value": lastName
            },
            {
              "display_name": "Phone Number",
              "variable_name": "Phone Number",
              "value": phoneNumber
            },
            {
              "display_name": "Service",
              "variable_name": "Service",
              "value": service
            },
            {
              "display_name": "Mopol",
              "variable_name": "Mopol",
              "value": mopol
            },
            {
              "display_name": "Location",
              "variable_name": "Location",
              "value": location
            },
            {
              "display_name": "Car Type",
              "variable_name": "Car Type",
              "value": carType
            },
            {
              "display_name": "Car",
              "variable_name": "Car",
              "value": car
            },
            {
              "display_name": "Pick Up Location",
              "variable_name": "Pick Up Location",
              "value": pickupLocation
            },
            {
              "display_name": "Pick Up Date",
              "variable_name": "Pick Up Date",
              "value": pickupDate
            },
            {
              "display_name": "Drop Off Location",
              "variable_name": "Drop Off Location",
              "value": dropOffLocation
            },
            {
              "display_name": "Booking Plan",
              "variable_name": "Booking Plan",
              "value": bookingplan
            },
            {
              "display_name": "Number of Days",
              "variable_name": "Number of Days",
              "value": numberOfDays
            },
          ]
        },
        key: 'pk_live_6acf406cd66900cee1359bc5109f49c68ddf5221',
        callback: (response) => {
          // Handle payment success
          const modalBody = document.querySelector('.modal-body');
          console.log('Payment successful:', response);
          if (response.status === 'success') {
            modalBody.innerHTML = "<p style='color: green;'>Payment successful! We'll be in touch to finalize.</p>";
          } else {
            modalBody.innerHTML = '<p>Payment failed. Please try again.</p>';
          }
        },
        onClose: () => {
          // Handle payment close
          console.log('Payment closed');
        }
      });
  
      // Open Paystack payment dialog
      handler.openIframe();
    };

    const today = new Date().toISOString().split('T')[0];

    const Pickuptime = () => {
      return(
        <div className="form-group">
          <label htmlFor="pickupTime">Pick Up Time *</label>
          <input type="time" className="form-control" id="pickupTime" value={pickupTime} onChange={(e) => setPickupTime(e.target.value)} min={today} />
        </div>
      )
    }

    const Bookingplan = () => {
      return(
        <div className="form-group">
          <label htmlFor="bookingplan">Booking Plan *</label>
          <select className="form-control" id="bookingplan" value={bookingplan} onChange={(e) => setBookingplan(e.target.value)}>
            <option value="Day">Day Time (8am to 6pm)</option>
            <option value="Night">Night Time (8pm - 6am)</option>
            <option value="Full">Full Day (8am - 6am)</option>
          </select>
        </div>
      )
    }

  return (
    <form onSubmit={handleSubmit} className="p-4 p-md-5 border rounded-3 bg-body-tertiary">
      {/* Step 3 fields */}
      {/* Pick Up Location */}
      <div className="form-group">
        <label htmlFor="pickupLocation">Pick Up Location *</label>
        <input type="text" className="form-control" id="pickupLocation" onChange={(e) => setPickupLocation(e.target.value)} />
      </div> 
      {/* Pick Up Date */}
      <div className="form-group">
        <label htmlFor="pickupDate">Pick Up Date *</label>
        <input type="date" className="form-control" id="pickupDate" value={pickupDate} onChange={(e) => setPickupDate(e.target.value)} min={today} />
      </div>
      {/* Pick up time */}
      {
        (service === "Airport Pickup" || service === "Interstate/Intrastate Travel") ? <Pickuptime /> : null
      }
      {/* Drop Off Location */}
      <div className="form-group">
        <label htmlFor="dropOffLocation">Drop Off Location *</label>
        <input type="text" className="form-control" id="dropOffLocation" value={dropOffLocation} onChange={(e) => setDropOffLocation(e.target.value)} />
      </div> 
      {/* Booking Plan */}
      {
        service === "Daily Rental" ? <Bookingplan /> : null
      }
      {/* Number of Days */}
      <div className="form-group">
        <label htmlFor="numberOfDays">Number of Days *</label>
        <select className="form-control" id="numberOfDays" value={numberOfDays} onChange={(e) => setNumberOfDays(e.target.value)}>
          {[...Array(30)].map((_, index) => (
            <option key={index + 1} value={index + 1}>{index + 1}</option>
          ))}
        </select>
      </div>
      <button type="button" className="btn btn-secondary mr-2" onClick={handlePrevStep}>Previous</button>
       {/*<PaystackButton {...componentProps} />*/}<button type="submit" onClick={handlePayment} className="btn btn-primary">Submit</button> 
    </form>
  );
  }


export default ReservationForm;