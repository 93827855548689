import Logo from "../logo.jpg"
import bg from "../funky-lines.png"

function Footer() {
  return (
    <div className="footer" id="footer" style={{backgroundImage: `url(${bg})`}}>
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <img src={Logo} width={100} className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1" />
            <span className="mb-3 mb-md-0 text-body-secondary">© 2024 Swift Drive Service Ltd.</span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3"><a className="text-body-secondary" href="https://instagram.com/swiftdriveservice" target="_blank"><i className="bi bi-instagram"></i></a></li>
            <li className="ms-3"><a className="text-body-secondary" href="https://facebook.com/swiftdriveservice" target="_blank"><i className="bi bi-facebook"></i></a></li>
            <li className="ms-3"><a className="text-body-secondary" href="https://wa.me/2348106720696" target="_blank"><i className="bi bi-whatsapp"></i></a></li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default Footer;