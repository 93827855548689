import Fleet from "../../fleet.png"
import bg from "../../funky-lines.png"


function Hero() {
  return (
    <div className="px-4 pt-5 text-center border-bottom " id="hero" style={{backgroundImage: `url(${bg})`}}>
      <h1 className="display-4 fw-bold text-body-emphasis">Nigeria's Easiest<br/>Car Rental Service</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Seamlessly book your luxury car and we'll handle the rest!<br/>Available in Abuja and Benin.</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3" data-bs-toggle="modal" data-bs-target="#reservationModal">Book Now</button>
          <a href="#cars" className="btn btn-outline-secondary btn-lg px-4">Our Fleet</a>
        </div>
      </div>
      <div className="overflow-hidden" style={{maxHeight: "30vh"}}>
        <div className="container px-5">
          <img src={Fleet} className="img-fluid mb-4" alt="Example Fleet" width="700" height="500" loading="lazy" />
        </div>
      </div>
    </div>
  );
}

export default Hero;