import React, { useEffect } from 'react';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function PlacesAutocompleteLoader({ children }) {
  useEffect(() => {
    const googleMapScript = document.getElementById('google-map-script');
    if (!googleMapScript) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key==AIzaSyDhZwvyEa-EWsMUJASzNPBupv9YrCvsDcw&libraries=places`, document.body, 'google-map-script');
    }

    return () => {
      if (googleMapScript) {
        googleMapScript.remove();
      }
    };
  }, []);

  return children;
}

export default PlacesAutocompleteLoader;