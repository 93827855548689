import security from "../../security.png"
import luxury from "../../luxury.png"
import interior from "../../interior.png"
import chauffeur from "../../chauffeur.png"

function Features() {
  return (
    <div className="container col-xxl-8 px-4 py-5" id="features">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img src={luxury} className="d-block mx-lg-auto img-fluid" alt="Luxurious and Comfortable Vehicles" width="700" height="500" loading="lazy" />
        </div>
        <div className="col-lg-6 text-left">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Luxurious and Comfortable Vehicles</h1>
          <p className="lead fs-4">Style and comfort are not an issue as we ensure our clients' have the best comfort and class money can buy.</p>
        </div>
      </div>
      <div className="row flex-lg-row align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img src={interior} className="d-block mx-lg-auto img-fluid" alt="Furnished and Regularly Serviced" width="700" height="500" loading="lazy" />
        </div>
        <div className="col-lg-6 text-left">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Furnished and Regularly Serviced</h1>
          <p className="lead">Travel in assurance. All our vehicles are regularly serviced to ensure optimal performance and are furnished to our clients' exquisite tastes.</p>
        </div>
      </div>
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img src={chauffeur} className="d-block mx-lg-auto img-fluid" alt="Professional Chauffeurs" width="700" height="500" loading="lazy" />
        </div>
        <div className="col-lg-6 text-left">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Professional Chauffeurs</h1>
          <p className="lead">You don’t have to worry about who is at the wheel. Our drivers possess many years of experience and are vetted properly.</p>
        </div>
      </div>
      <div className="row flex-lg-row align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img src={security} className="d-block mx-lg-auto img-fluid" alt="Best in-car security features" width="700" height="500" loading="lazy" />
        </div>
        <div className="col-lg-6 text-left">
          <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Best in-car security features</h1>
          <p className="lead">Some of our vehicles are bulletproof and all of them are tracked in real-time to ensure safe travels.</p>
        </div>
      </div>
    </div>
  );
}

export default Features;