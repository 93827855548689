import { useState } from 'react';

import './App.css';
import './custom.css';
import Hero from './components/home/Hero';
import Services from './components/home/Services';
// import About from './components/home/About';
// import Stats from './components/home/Stats';
import Cars from './components/home/Cars';
import Features from './components/home/Features';
import Support from './components/home/Support';
import Header from './components/Header';
import Footer from './components/Footer';
import ReservationForm from "./components/home/Form"
import Reviews from './components/home/Reviews';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      {/* <Services /> */}
      {/* <About /> */}
      <Cars />
      <hr style={{width: "50%", margin: "0 auto"}}/>
      {/* <Stats /> */}
      <Features />
      <hr style={{width: "50%", margin: "0 auto"}}/>
      <Reviews />
      <hr style={{width: "50%", margin: "0 auto"}}/>
      <Support />
      <Footer />
      <ReservationForm/>
    </div>
  );
}

export default App;
