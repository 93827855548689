import Logo from "../../logo.png"

function Navbard() {
  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div className="container">
        <a className="navbar-brand" href="#"><img src={Logo} alt="Logo"/></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#cars">Fleet</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#support">Contact</a>
            </li>
          </ul>
          <button type="button" className="btn btn-outline-info btn-md px-4 me-sm-3 fw-bold"  data-bs-toggle="modal" data-bs-target="#reservationModal">Book Your Ride</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbard;