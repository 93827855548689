
function Support() {
  return (
    <div className="container col-xl-10 col-xxl-8 px-4 py-5" id="support">
      <div className="row align-items-center g-lg-5 py-5">
        <div className="col-lg-6 text-center text-lg-start">
          <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">Get in touch</h1>
          <p className="col-lg-10 fs-4">Our support is available 24/7 to attend to your needs.</p>
          <p>You can use the form to send us a message or reach us via any of the methods below:</p>
          <ul className="list-unstyled">
            <li className="ms-3"><i className="bi bi-geo-alt" style={{marginRight: "10px"}}></i>Federal Capital Territory, Abuja</li>
            <li className="ms-3"><a className="text-body-secondary" href="mailto:swiftdriveservices@gmail.com" style={{textDecoration: "none"}}><i className="bi bi-envelope-at" style={{marginRight: "10px"}}></i>swiftdriveservices@gmail.com</a></li>
            <li className="ms-3"><a className="text-body-secondary" href="tel:+2348106720696" style={{textDecoration: "none"}}><i className="bi bi-phone" style={{marginRight: "10px"}}></i>+234 810 6720 696</a></li>
          </ul>
        </div>
        <div className="col-md-10 mx-auto col-lg-6">
          <form className="p-4 p-md-5 border rounded-3 bg-body-tertiary">
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="name" placeholder="Full Name"/>
              <label htmlFor="name">Full Name</label>
            </div>
            <div className="form-floating mb-3">
              <input type="email" className="form-control" id="email" placeholder="name@example.com"/>
              <label htmlFor="email">Email address</label>
            </div>
            <div className="form-floating mb-3">
              <textarea className="form-control" id="message" name="message" placeholder="Type your message" style={{height: "120px"}}></textarea>
              <label htmlFor="message">Message</label>
            </div>
            <button className="w-100 btn btn-lg btn-primary" type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Support;